import React from 'react';
import { useState, useEffect, useContext, useRef } from 'react';
import {
  Alert,
  Space,
  Modal,
  Button,
  Spin,
  Drawer,
  Progress,
  message,
  Tooltip,
} from 'antd';
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import Web3 from 'web3';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AccountContext } from '../../context/AccountContext';
import {
  cancelStream,
  connectWallet,
  withdrawFromStream,
  parseStreams,
  createStream,
  balanceOf,
} from '../../helpers/wallet';
import Stream from '../Stream/Stream';
import './Dashboard.less';

import Copy from '../../images/copy.svg';

const Dashboard = () => {
  const [streams, setStreams] = useState([]);
  const [totalTokens, setTotalTokens] = useState({
    in: 0,
    out: 0,
    inDep: 0,
    outDep: 0,
    inTime: '',
    outTime: '',
  });
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState('');
  const [actionStatus, setActionStatus] = useState({
    status: '',
    type: '',
  });
  const [amount, setAmount] = useState({
    streamId: '',
    amount: 0,
  });
  const [visible, setVisible] = useState(false);
  const fetcherInterval = useRef(null);
  const apiUrl = useRef(null);
  const client = useRef(null);
  const { account, changeAccount, chainId, changeNetwork } =
    useContext(AccountContext);
  const FETCH_TIME = 15000;

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onConnectWallet = async () => {
    if (window.ethereum) {
      let response = await connectWallet();
      if (response.status) {
        changeAccount(response.account);
        message.success('Successfully connected!');
      } else {
        message.error('Something went wrong!');
      }
    } else {
      message.error(
        'Please install Metamask wallet extension to use this app.'
      );
      setTimeout(() => {
        let popup = window.open('https://metamask.io/', '_blank');
        if (popup) {
          popup.focus();
        } else {
          message.error(
            'You have disabled popups. Please enable the popups or follow this link: https://metamask.io/'
          );
        }
      }, 3000);
    }
  };

  const APIURL =
    chainId === '0x38'
      ? 'https://api.thegraph.com/subgraphs/name/vijaykumarktg/bsc-stream'
      : chainId === '0x61'
      ? 'https://api.thegraph.com/subgraphs/name/vijaykumarktg/bsc-stream-chapel'
      : null;

  const CLIENT = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const getSenderStreams = `
    query {
      streams(where:{sender: "${account}"},orderBy: streamId, orderDirection: desc) {
        streamId
        recipient
        sender
        startTime
        stopTime
        deposit
        amount
        tokenAddress
        tx
        status
      }
    }
  `;

  const getRecipientStreams = `
  query {
    streams(where:{recipient: "${account}"},orderBy: streamId, orderDirection: desc) {
      streamId
      recipient
      sender
      startTime
      stopTime
      deposit
      amount
      tokenAddress
      tx
      status
    }
  }
`;

  const fetchStreams = async (text) => {
    if (chainId === '0x61' || chainId === '0x38') {
      try {
        let senderLists = await (
          await CLIENT.query({ query: gql(getSenderStreams) })
        ).data.streams;
        let recipientLists = await (
          await CLIENT.query({ query: gql(getRecipientStreams) })
        ).data.streams;
        let streamsLists = [...senderLists, ...recipientLists];
        let obj = await parseStreams(streamsLists, account, chainId);
        setStreams(obj.streams);
        setTotalTokens({
          in: obj.totalInToken,
          out: obj.totalOutToken,
          inDep: obj.totalInDep,
          outDep: obj.totalOutDep,
          inTime: obj.totalInTime,
          outTime: obj.totalOutTime,
        });
        text && setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (chainId === '0x61' || chainId === '0x38') {
      setLoading(true);
      fetchStreams('load');
      fetcherInterval.current = setInterval(fetchStreams, FETCH_TIME);
      return () => {
        clearInterval(fetcherInterval.current);
        fetcherInterval.current = null;
      };
    }
  }, [account, chainId]);

  /**
   * Handle create stream
   */
  const createStreamHandler = async (
    recipient,
    deposit,
    address,
    startTime,
    stopTime
  ) => {
    if (fetcherInterval.current) {
      clearInterval(fetcherInterval.current);
      fetcherInterval.current = null;
    }
    setProcessing('Approving deposit');
    let result = await createStream(
      recipient,
      deposit,
      address,
      startTime,
      stopTime,
      account,
      setProcessing,
      chainId
    );
    if (result) {
      message.success('Created a new stream!');
      setProcessing('');
      setVisible(false);
    } else {
      setProcessing('');
      setVisible(false);
      message.error('Something went wrong!');
    }
    fetchStreams();
    fetcherInterval.current = setInterval(fetchStreams, FETCH_TIME);
  };

  /**
   * Handle create stream
   */
  const withdrawHandler = (streamId, amount) => {
    setActionStatus({
      status: 'Withdraw Amount',
      type: 'withdraw',
    });
    setAmount({ streamId, amount });
  };

  const withdraw = async () => {
    setActionStatus({
      status: 'Withdrawing tokens',
      type: 'withdraw',
    });
    if (fetcherInterval.current) {
      clearInterval(fetcherInterval.current);
      fetcherInterval.current = null;
    }
    let result = await withdrawFromStream(
      amount.streamId,
      amount.amount,
      account,
      chainId
    );
    if (result) {
      setActionStatus({
        status: '',
        type: '',
      });
      setAmount({ streamId: '', amount: 0 });
      message.success('Withdrew the deposit!');
    } else {
      setActionStatus({
        status: '',
        type: '',
      });
      setAmount({ streamId: '', amount: 0 });
      message.error('Something went wrong!');
    }
    fetchStreams();
    fetcherInterval.current = setInterval(fetchStreams, FETCH_TIME);
  };

  /**
   * Handle cancel stream
   */
  const cancelStreamHandler = async (streamId) => {
    if (fetcherInterval.current) {
      clearInterval(fetcherInterval.current);
      fetcherInterval.current = null;
    }
    setActionStatus({
      status: 'Cancelling stream',
      type: 'cancel',
    });
    let result = await cancelStream(streamId, account, chainId);
    if (result) {
      setActionStatus({ status: '', type: 'cancel' });
      message.success('Cancelled the stream!');
    } else {
      setActionStatus({ status: '', type: 'cancel' });
      message.error('Something went wrong!');
    }
    fetchStreams();
    fetcherInterval.current = setInterval(fetchStreams, FETCH_TIME);
  };

  return (
    <>
      {account ? (
        <div>
          <div>
            {chainId !== '0x61' && chainId !== '0x38' && (
              <div className='wrong-network'>
                <Modal
                  centered
                  title='Wrong Network'
                  wrapClassName='wrong-network-modal'
                  closable={false}
                  width='50%'
                  visible={true}
                  footer={null}>
                  <p>
                    You are on wrong network. Please connect to{' '}
                    <button
                      className='network'
                      onClick={() => changeNetwork('mainnet')}>
                      BSC main net
                    </button>{' '}
                    or{' '}
                    <button
                      className='network'
                      onClick={() => changeNetwork('testnet')}>
                      BSC test net
                    </button>
                    .
                  </p>
                </Modal>
              </div>
            )}
            {/* <main className='header-container'>
              <div className='total-tokens total-tokens-in'>
                <h2>Total Incoming</h2>
                <div className='in-status'>
                  <Progress
                    strokeColor={{
                      0: '#5985FE',
                      100: '#afc4ff',
                    }}
                    width='200px'
                    type='circle'
                    percent={Number.parseFloat(
                      (totalTokens.in / totalTokens.inDep) * 100
                    ).toFixed(3)}
                  />
                  <div>
                    <div className='total-status'>
                      <p>
                        Total Incoming Deposit:{' '}
                        <span>{totalTokens.inDep / 100}k</span> Tokens
                      </p>
                    </div>
                    <div className='total-status'>
                      <p>
                        Total Received Tokens:{' '}
                        <span>{totalTokens.in / 100}k</span> Tokens
                      </p>
                    </div>
                    <p className='total-time'>
                      {totalTokens.inTime
                        ? totalTokens.inTime + ' remaining'
                        : 'No pending streams.'}
                    </p>
                  </div>
                </div>
              </div>
              <div className='total-tokens total-tokens-out'>
                <h2>Total Outgoing</h2>
                <div className='out-status'>
                  <Progress
                    strokeColor={{
                      0: '#FE9259',
                      100: '#FFDB8B',
                    }}
                    width='200px'
                    type='circle'
                    percent={Number.parseFloat(
                      (totalTokens.out / totalTokens.outDep) * 100
                    ).toFixed(3)}
                  />
                  <div>
                    <div className='total-status'>
                      <p>
                        Total Outgoing Deposit:{' '}
                        <span>{totalTokens.outDep / 100}k</span> Tokens
                      </p>
                    </div>
                    <div className='total-status'>
                      <p>
                        Total Transferred Tokens:{' '}
                        <span>{totalTokens.out / 100}k</span> Tokens
                      </p>
                    </div>
                    <p className='total-time'>
                      {totalTokens.outTime
                        ? totalTokens.outTime + ' remaining.'
                        : 'No pending streams.'}
                    </p>
                  </div>
                </div>
              </div>
            </main> */}
            <div className='dashboard-header'>
              <div>
                <h6>
                  <b>Dashboard</b>
                </h6>
                <button className='button' onClick={showDrawer}>
                  Stream
                </button>
              </div>
              <hr />
            </div>
            {loading ? (
              <div className='loader'>
                <Spin />
              </div>
            ) : (
              <div className='stream-list'>
                {streams.length ? (
                  <table className='table'>
                    <tr className='header-row'>
                      <th>In/Out</th>
                      <th>STATUS</th>
                      <th>From</th>
                      <th>TO</th>
                      <th>VALUE</th>
                      <th>PROGRESS</th>
                      <th>START TIME</th>
                      <th>STOP TIME</th>
                      <th>LINK</th>
                      <th>Action</th>
                    </tr>
                    {streams.map((stream) => (
                      <tr
                        key={stream.streamId}
                        className={`data-row ${
                          stream.sender === account ? 'sender' : 'recipient'
                        }`}>
                        <td className='inout'>
                          {stream.sender === account ? (
                            <span className='out'>Outgoing</span>
                          ) : (
                            <span className='in'>Incoming</span>
                          )}
                        </td>
                        <td className='status'>
                          <span>Streaming</span>
                        </td>
                        <td className='from' title={stream.sender}>
                          {stream.sender.slice(0, 5)}...
                          <CopyToClipboard
                            text={stream.sender}
                            onCopy={() =>
                              message.success('Copied the sender address')
                            }>
                            <Tooltip title='Click to copy sender address'>
                              <img src={Copy} alt='copy icon' />
                            </Tooltip>
                          </CopyToClipboard>
                        </td>
                        <td className='to' title={stream.recipient}>
                          {stream.recipient.slice(0, 5)}...
                          <CopyToClipboard
                            text={stream.recipient}
                            onCopy={() =>
                              message.success('Copied the recipient address')
                            }>
                            <Tooltip title='Click to copy recipient address'>
                              <img src={Copy} alt='copy icon' />
                            </Tooltip>
                          </CopyToClipboard>
                        </td>
                        <td className='value'>
                          {parseFloat(
                            Web3.utils.fromWei(stream.deposit) > 1000
                              ? `${Web3.utils.fromWei(stream.deposit) / 1000}k`
                              : Web3.utils.fromWei(stream.deposit)
                          ).toFixed(2)}
                          <span>{stream.tokenUnit}</span>
                        </td>
                        <td className='progressX'>
                          {stream.status !== 'active' ? (
                            <>
                              <p>
                                <span>
                                  {Math.round(
                                    Number.parseFloat(
                                      Web3.utils.fromWei(stream.amount)
                                    )
                                  )}
                                </span>{' '}
                                {stream.tokenUnit} trasferred.
                              </p>
                              <Progress
                                percent={Number(stream.progress)}
                                status={
                                  Number(stream.progress) === 100
                                    ? 'success'
                                    : 'exception'
                                }
                              />
                            </>
                          ) : (
                            <div style={{ marginTop: '-7px', width: '100%' }}>
                              <Progress
                                strokeColor={
                                  stream.sender === account
                                    ? {
                                        '0%': '#FE9259',
                                        '100%': '#FFDB8B',
                                      }
                                    : {
                                        '0%': '#5985FE',
                                        '100%': '#8BAAFF',
                                      }
                                }
                                percent={Number(stream.progress)}
                                status={
                                  Number(stream.progress) === 100
                                    ? 'success'
                                    : 'active'
                                }
                              />
                            </div>
                          )}
                        </td>
                        <td className='time'>
                          {stream.startTime.toLocaleString()}
                        </td>
                        <td className='time'>
                          {stream.stopTime.toLocaleString()}
                        </td>
                        <td className='link'>
                          <a
                            href={`${
                              chainId === '0x38'
                                ? 'https://bscscan.com/tx/' + stream.tx
                                : 'https://testnet.bscscan.com/tx/' + stream.tx
                            }`}
                            target='_blank'
                            rel='noreferrer'>
                            Link
                          </a>
                        </td>
                        <td className='action'>
                          {stream.status === 'active' ? (
                            stream.sender === account ? (
                              <button
                                className='cancel-btn'
                                onClick={() =>
                                  cancelStreamHandler(stream.streamId)
                                }>
                                Cancel
                              </button>
                            ) : (
                              <button
                                className='withdraw-btn'
                                onClick={async () =>
                                  withdrawHandler(
                                    stream.streamId,
                                    await balanceOf(
                                      stream.streamId,
                                      account,
                                      chainId
                                    )
                                  )
                                }>
                                Withdraw
                              </button>
                            )
                          ) : stream.status === 'cancelled' ? (
                            <button className='cancel-btn' disabled>
                              Cancelled
                            </button>
                          ) : (
                            <button disabled className='withdraw-btn'>
                              Withdrawn
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </table>
                ) : (
                  <div className='no-streams'>No streams yet!</div>
                )}
              </div>
            )}
          </div>
          <Drawer
            height='85%'
            placement='bottom'
            closable={false}
            onClose={onClose}
            visible={visible}
            key='createstream'>
            <Stream
              createStream={createStreamHandler}
              close={onClose}
              account={account}
              processing={processing}
              chainId={chainId}
            />
          </Drawer>
          {actionStatus.type === 'withdraw' &&
            actionStatus.status &&
            amount.amount && (
              <Modal
                centered
                closable={false}
                wrapClassName='modalX'
                width='30%'
                visible={true}
                footer={null}>
                {actionStatus.status === 'Withdraw Amount' ? (
                  <>
                    <h5>Withdraw Amount</h5>
                    <p>Available amount: {Web3.utils.fromWei(amount.amount)}</p>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '15px',
                      }}>
                      <Button
                        onClick={() =>
                          setActionStatus({
                            status: '',
                            type: '',
                          })
                        }>
                        Cancel
                      </Button>
                      <button className='submit-btn' onClick={withdraw}>
                        Withdraw
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <h5>{actionStatus.status}</h5>
                    <Spin />
                  </>
                )}
              </Modal>
            )}
          {actionStatus.type === 'cancel' && actionStatus.status && (
            <Modal
              centered
              closable={false}
              wrapClassName='modalX'
              width='20%'
              visible={true}
              footer={null}>
              <h5>{actionStatus.status}</h5>
              <Spin />
            </Modal>
          )}
        </div>
      ) : (
        <div className='connect-container'>
          <h2>Please connect to your wallet.</h2>
          <button className='wallet' onClick={onConnectWallet}>
            CONNECT WALLET
          </button>
        </div>
      )}
    </>
  );
};

export default Dashboard;
