import Web3 from 'web3';
import {
  CONTRACT_ABI,
  CONTRACT_ADDRESS,
  CONTRACT_ABI_TESTNET,
  CONTRACT_ADDRESS_TESTNET,
  TOKENSABIS,
  TOKENSADDRESSES,
} from '../constants';
import BN from 'bn.js';

let web3 = new Web3(Web3.givenProvider);
const contractMainnet = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
const contractTestnet = new web3.eth.Contract(
  CONTRACT_ABI_TESTNET,
  CONTRACT_ADDRESS_TESTNET
);

export const connectWallet = async () => {
  try {
    if (typeof window.web3 != 'undefined') {
      web3 = new Web3(window.web3.currentProvider);
    } else {
      return { status: false };
    }
    let account = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    return { status: true, account: account[0] };
  } catch (error) {
    console.log('error', error?.message);
    return { status: false };
  }
};

/**
 * 2. Get a particular stream via stream id
 * and return an object with sender, recipient,
 * deposit, tokenAddress, startTime, stopTime,
 * remainingBalance and ratePerSecond properties
 * @param {number} streamId
 * @returns
 */
export const getStream = async (streamId, chainId) => {
  let contract = chainId === '0x38' ? contractMainnet : contractTestnet;
  try {
    let result = await contract.methods.getStream(streamId).call();
    console.log('Get Stream: ');
    console.log(result);
  } catch (error) {
    console.log('Get Stream Error: ');
    console.log(error.message);
  }
  return {
    sender: 'address',
    recipient: 'address',
    deposit: 'number',
    tokenAddress: 'address',
    startTime: 'number',
    stopTime: 'number',
    remainingBalance: 'number',
    ratePerSecond: 'number',
  };
};

/**
 * 3. Get the balance of a particular stream
 * of a particular person via stream id and
 * user address and return the balance in number
 * @param {number} streamId
 * @param {address} who
 * @returns
 */
export const balanceOf = async (streamId, who, chainId) => {
  let contract = chainId === '0x38' ? contractMainnet : contractTestnet;
  try {
    let result = await contract.methods.balanceOf(streamId, who).call();
    return result;
  } catch (error) {
    console.log('Balance of Error: ');
    console.log(error);
  }
  return 0;
};

/**
 * 4. Create a stream with recipient, deposit,
 * token address, start time, and stop time and
 * return new stream id as number
 * @param {address} recipient
 * @param {number} deposit
 * @param {address} address
 * @param {unix time} startTime
 * @param {unix time} stopTime
 * @param {address} account
 * @return {number}
 */
export const createStream = async (
  recipient,
  deposit,
  address,
  startTime,
  stopTime,
  account,
  setProcessing,
  chainId
) => {
  let contract = chainId === '0x38' ? contractMainnet : contractTestnet;

  try {
    deposit = new BN(deposit);

    let tokenContractABI = TOKENSABIS.get(address.toLowerCase());
    const tokenContract = new web3.eth.Contract(tokenContractABI, address);
    let allowance = new BN(
      await tokenContract.methods.allowance(address, recipient).call()
    );
    let approveAmount = allowance.add(deposit);
    let receipt;
    receipt = await tokenContract.methods
      .approve(
        chainId === '0x38' ? CONTRACT_ADDRESS : CONTRACT_ADDRESS_TESTNET,
        approveAmount
      )
      .send({ from: account });
    setProcessing('Creating a stream');
    receipt = await contract.methods
      .createStream(recipient, deposit, address, startTime, stopTime)
      .send({ from: account, gasPrice: 10000000000 });
    return receipt.events.CreateStream.returnValues.streamId ? true : false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

/**
 * 5. Withdraw balance from stream with stream id
 * and will return a boolean value (true for success
 *  and false for fail)
 * @param {number} streamId
 * @param {number} amount
 * @param {address} account
 * @returns {boolean}
 */
export const withdrawFromStream = async (
  streamId,
  amount,
  account,
  chainId
) => {
  let contract = chainId === '0x38' ? contractMainnet : contractTestnet;
  try {
    const receipt = await contract.methods
      .withdrawFromStream(streamId, amount)
      .send({ from: account, gasPrice: 10000000000 });
    return receipt.events.WithdrawFromStream.returnValues.streamId
      ? true
      : false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

/**
 * 6. Cancel a stream with stream id and return
 * boolean (true for success and false for fail)
 * @param {number} streamId
 */
export const cancelStream = async (streamId, account, chainId) => {
  let contract = chainId === '0x38' ? contractMainnet : contractTestnet;
  try {
    let receipt = await contract.methods
      .cancelStream(streamId)
      .send({ from: account, gasPrice: 10000000000 });
    return receipt.events.CancelStream.returnValues.streamId ? true : false;
  } catch (error) {
    return false;
  }
};

const getRemainingTime = (delta) => {
  var r = {};
  var s = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  Object.keys(s).forEach(function (key) {
    r[key] = Math.floor(delta / s[key]);
    delta -= r[key] * s[key];
  });

  let result = '';
  if (r.year) {
    result += `${r.year} ${r.year === 1 ? 'Yr' : 'Yrs'} `;
  }
  if (r.month) {
    result += `${r.month} ${r.month === 1 ? 'Month' : 'Months'} `;
  }
  if (r.week) {
    result += `${r.week} ${r.week === 1 ? 'Week' : 'Weeks'} `;
  }
  if (r.day) {
    result += `${r.day} ${r.day === 1 ? 'Day' : 'Days'} `;
  }
  if (r.hour) {
    result += `${r.hour} ${r.hour === 1 ? 'Hr' : 'Hrs'} `;
  }
  if (r.minute) {
    result += `${r.minute} ${r.minute === 1 ? 'Min' : 'Mins'} `;
  }
  if (r.second) {
    result += `${r.second} ${r.second === 1 ? 'Sec' : 'Secs'}`;
  }
  return result;
};

export const parseStreams = async (streams, account, chainId) => {
  let contract = chainId === '0x38' ? contractMainnet : contractTestnet;
  let TOKENS = TOKENSADDRESSES;
  let totalInToken = 0,
    totalInDep = 0,
    totalInTime = 0,
    totalOutToken = 0,
    totalOutDep = 0,
    totalOutTime = 0,
    tempSenderTime,
    tempRecipientTime,
    tempCurrentTime;
  streams.sort((a, b) => Number(b.streamId) - Number(a.streamId));
  let results = await Promise.all(
    streams.map(async (stream) => {
      if (stream.status === 'active') {
        return await contract.methods
          .balanceOf(stream.streamId, stream.sender)
          .call();
      } else {
        return Promise.resolve('0');
      }
    })
  );

  let newStreams = streams.map((stream, i) => {
    let newStream = { ...stream };
    newStream.startTime = new Date(newStream.startTime * 1000);
    newStream.stopTime = new Date(newStream.stopTime * 1000);
    newStream.senderBalance = results[i];
    if (newStream.status !== 'active') {
      newStream.progress = Math.round(
        (newStream.amount / newStream.deposit) * 100
      );
    } else {
      if (stream.sender === account) {
        totalOutToken +=
          Number(web3.utils.fromWei(newStream.deposit + '')) -
          Number(web3.utils.fromWei(results[i]));
        tempSenderTime = new Date(newStream.stopTime).getTime();
        tempCurrentTime = new Date().getTime();
        if (tempSenderTime > tempCurrentTime) {
          totalOutTime += Math.abs(tempSenderTime - tempCurrentTime) / 1000;
        }
        totalOutDep += Number(web3.utils.fromWei(newStream.deposit));
      } else {
        totalInToken +=
          Number(web3.utils.fromWei(newStream.deposit + '')) -
          Number(web3.utils.fromWei(results[i]));
        tempRecipientTime = new Date(newStream.stopTime).getTime();
        tempCurrentTime = new Date().getTime();
        if (tempRecipientTime > tempCurrentTime) {
          totalInTime += Math.abs(tempRecipientTime - tempCurrentTime) / 1000;
        }
        totalInDep += Number(web3.utils.fromWei(newStream.deposit));
      }
      newStream.progress = Math.round(
        ((newStream.deposit - results[i]) / newStream.deposit) * 100
      );
    }
    newStream.tokenUnit = TOKENS.get(
      String(newStream.tokenAddress).toLowerCase()
    );
    return newStream;
  });
  totalInTime = getRemainingTime(totalInTime);
  totalOutTime = getRemainingTime(totalOutTime);
  return {
    streams: newStreams,
    totalInToken,
    totalInDep,
    totalOutToken,
    totalOutDep,
    totalInTime,
    totalOutTime,
  };
};
