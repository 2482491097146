import { Button, Input } from 'antd';
import { useState, useEffect } from 'react';
import { Spin, Select, Modal } from 'antd';
import Web3 from 'web3';
import BN from 'bn.js';
import { TOKENSADDRESSES } from '../../constants';
import './Stream.less';

const Stream = ({ createStream, close, processing, chainId }) => {
  const [recipient, setRecepientAddress] = useState('');
  const [tokenAddress, setTokenAddress] = useState('');
  const [unixStartTime, setUnixStartTime] = useState('');
  const [unixStopTime, setUnixStopTime] = useState('');
  const [deposit, setDeposit] = useState('0');
  const [recommendations, setRecommendations] = useState([]);
  const [depositError, setDepositError] = useState('');
  const [startTimeError, setStartTimeError] = useState('');
  const [stopTimeError, setStopTimeError] = useState('');

  const [durationSeconds, setDurationSeconds] = useState(0);
  const [durationMinutes, setDurationMinutes] = useState(0);
  const [durationHours, setDurationHours] = useState(0);
  const [durationDays, setDurationDays] = useState(0);
  const [durationWeeks, setDurationWeeks] = useState(0);
  const [durationMonths, setDurationMonths] = useState(0);
  const [durationYears, setDurationYears] = useState(0);

  const { Option } = Select;

  // const options_Years = [
  //   { value: 0, label: '0 Years' },
  //   { value: 1, label: '1 Year' },
  //   { value: 2, label: '2 Years' },
  //   { value: 3, label: '3 Years' },
  // ];
  // const options_Weeks = [
  //   { value: 0, label: '0 Weeks' },
  //   { value: 1, label: '1 Week' },
  //   { value: 2, label: '2 Weeks' },
  //   { value: 3, label: '3 Weeks' },
  //   { value: 4, label: '4 Weeks' },
  //   { value: 5, label: '5 Weeks' },
  //   { value: 6, label: '6 Weeks' },
  //   { value: 7, label: '7 Weeks' },
  //   { value: 8, label: '8 Weeks' },
  // ];
  // const options_Days = [
  //   { value: 0, label: '0 Days' },
  //   { value: 1, label: '1 Day' },
  //   { value: 2, label: '2 Days' },
  //   { value: 3, label: '3 Days' },
  //   { value: 4, label: '4 Days' },
  //   { value: 5, label: '5 Days' },
  //   { value: 6, label: '6 Days' },
  //   { value: 7, label: '7 Days' },
  //   { value: 8, label: '8 Days' },
  // ];
  // const options_Hours = [
  //   { value: 0, label: '0 Hours' },
  //   { value: 1, label: '1 Hour' },
  //   { value: 2, label: '2 Hours' },
  //   { value: 3, label: '3 Hours' },
  //   { value: 4, label: '4 Hours' },
  //   { value: 5, label: '5 Hours' },
  //   { value: 6, label: '6 Hours' },
  //   { value: 7, label: '7 Hours' },
  //   { value: 8, label: '8 Hours' },
  // ];
  // const options_Mins = [
  //   { value: 0, label: '0 Minutes' },
  //   { value: 1, label: '1 Minute' },
  //   { value: 2, label: '2 Minutes' },
  //   { value: 3, label: '3 Minutes' },
  //   { value: 4, label: '4 Minutes' },
  //   { value: 5, label: '5 Minutes' },
  //   { value: 6, label: '6 Minutes' },
  //   { value: 7, label: '7 Minutes' },
  // ];
  //var SelectedOption;

  useEffect(() => {
    onUnixStopTimeChange();
  }, [
    durationSeconds,
    durationMinutes,
    durationHours,
    durationDays,
    durationWeeks,
    durationMonths,
    durationYears,
  ]);

  useEffect(() => {
    setTokenAddress(
      chainId === '0x38'
        ? '0x6c1eFbEd2F57dd486Ec091dFfd08eE5235A570b1'
        : '0xf5dc6821eEFD8638250820d344924B494F1749Db'
    );
  }, [chainId]);

  const onUnixStartTimeChange = (time) => {
    let newTime = new Date(time).getTime();
    let currentTime = new Date().getTime();
    if (newTime > currentTime) {
      setUnixStartTime(newTime / 1000);
      setStartTimeError('');
    } else {
      setStartTimeError(
        'Please choose a unix start time which is greater then current machine time.'
      );
    }
  };

  const onUnixStopTimeChange = () => {
    if (!unixStartTime) {
      setStopTimeError('Please fill the start time first.');
      return;
    }
    let newTime =
      unixStartTime +
      Number(durationSeconds) +
      60 * Number(durationMinutes) +
      3600 * Number(durationHours) +
      86400 * Number(durationDays) +
      604800 * Number(durationWeeks) +
      2592000 * Number(durationMonths) +
      31556926 * Number(durationYears);

    let currentTime = new Date().getTime();
    if (newTime <= currentTime / 1000) {
      setStopTimeError(
        'You are choosing a duration which is less than your current machine time.'
      );
    } else if (newTime <= unixStartTime) {
      setStopTimeError(
        'Please choose a unix stop time which is greater than unix start time you chose.'
      );
    } else {
      setStopTimeError('');
      setUnixStopTime(newTime);
    }
  };

  // const getRandomInt = (max) => {
  //   let result = Math.floor(Math.random() * max) + 1;
  //   return result;
  // };

  const findClosestDivisible = (n, m) => {
    let q = n.div(m);
    let n1 = m.mul(q);
    let n2 = m.mul(q.add(new BN(1)));
    return [n1.toString(), n2.toString()];
  };

  const onDepositChange = (deposit) => {
    if (deposit) {
      if (unixStartTime && unixStopTime) {
        deposit = new BN(deposit);
        let duration = new BN(unixStopTime - unixStartTime);
        if (deposit.mod(duration).toString() === '0') {
          setDeposit(deposit);
          setRecommendations([]);
          setDepositError('');
        } else {
          // let multipliers = [
          //   Web3.utils.toWei(String(getRandomInt(100)), 'mwei'),
          //   Web3.utils.toWei(String(getRandomInt(100)), 'lovelace'),
          //   Web3.utils.toWei(String(getRandomInt(100)), 'gwei'),
          //   Web3.utils.toWei(String(getRandomInt(100)), 'shannon'),
          //   Web3.utils.toWei(String(getRandomInt(100)), 'nano'),
          //   Web3.utils.toWei(String(getRandomInt(1000)), 'microether'),
          //   Web3.utils.toWei(String(getRandomInt(10)), 'finney'),
          //   Web3.utils.toWei(String(getRandomInt(200)), 'finney'),
          //   Web3.utils.toWei(String(getRandomInt(50)), 'ether'),
          //   Web3.utils.toWei(String(getRandomInt(100)), 'ether'),
          // ];
          let array = findClosestDivisible(deposit, duration);
          setDeposit(String(deposit));
          setDepositError(
            'Deposit value must be multiple of (unix stop time - unix start time).'
          );
          setRecommendations(array);
        }
      } else {
        setDepositError('Please fill unix start time and duration first.');
      }
    }
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    createStream(recipient, deposit, tokenAddress, unixStartTime, unixStopTime);
  };

  return (
    <div>
      <header className='container'>
        <div className='sublier-head'>
          <h1>Token Stream</h1>
          <Button shape='round' onClick={close}>
            x
          </Button>
        </div>
        <p>Please fill all the following fields.</p>
        <h5>Create a stream</h5>
        <br />
        <div className='form-containerX'>
          <form onSubmit={(e) => onSubmitForm(e)}>
            <div className='row '>
              <div className='form-group col-sm'>
                <h6>Recipient Address</h6>
                <input
                  type='text'
                  className='form-control'
                  id='formGroupExampleInput'
                  value={recipient}
                  onChange={(e) => setRecepientAddress(e.target.value)}
                  placeholder='Recipient Address'
                  name='recipient Address'
                  required
                />
              </div>
              <div className='col-sm'>
                <h6>Token Address</h6>
                <Select
                  defaultValue={
                    chainId === '0x38'
                      ? '0x6c1eFbEd2F57dd486Ec091dFfd08eE5235A570b1'
                      : '0xf5dc6821eEFD8638250820d344924B494F1749Db'
                  }
                  required
                  aria-required
                  placeholder='Token Address'
                  onChange={(value) => setTokenAddress(value)}>
                  {chainId === '0x38' ? (
                    <Option value='0x6c1eFbEd2F57dd486Ec091dFfd08eE5235A570b1'>
                      PNDR
                    </Option>
                  ) : (
                    <>
                      <Option value='0xf5dc6821eEFD8638250820d344924B494F1749Db'>
                        PNDR
                      </Option>
                      <Option value='0x84A1F4cb595dE6a52eA89B3D3c557c16740cE8f8'>
                        XYZ
                      </Option>
                    </>
                  )}
                </Select>
              </div>
            </div>

            <br />
            <div className='row'>
              <div className='col-sm form-group'>
                <h6>Start Time (Unix Time)</h6>
                <input
                  type='datetime-local'
                  className='form-control'
                  id='formGroupExampleInput'
                  defaultValue={unixStartTime * 1000}
                  onChange={(e) => onUnixStartTimeChange(e.target.value)}
                  placeholder='Start Time (Unix Time)'
                  name='unixStartTime'
                />
                <div className='error'>{startTimeError}</div>
              </div>
              <div className='col-sm form-group'>
                <h6>Duration</h6>
                <div style={{ display: 'flex' }}>
                  <Input
                    type='number'
                    min='0'
                    max='20'
                    placeholder='Years'
                    className='form-control'
                    onChange={(e) => setDurationYears(Number(e.target.value))}
                    name='unixStopTime'
                  />
                  <Input
                    type='number'
                    min='0'
                    max='11'
                    placeholder='Months'
                    className='form-control'
                    onChange={(e) => setDurationMonths(Number(e.target.value))}
                    name='unixStopTime'
                  />
                  <Input
                    type='number'
                    min='0'
                    max='51'
                    placeholder='Weeks'
                    className='form-control'
                    onChange={(e) => setDurationWeeks(Number(e.target.value))}
                    name='unixStopTime'
                  />
                  <Input
                    type='number'
                    min='0'
                    max='364'
                    placeholder='Days'
                    className='form-control'
                    onChange={(e) => setDurationDays(Number(e.target.value))}
                    name='unixStopTime'
                  />
                  <Input
                    type='number'
                    min='0'
                    max='23'
                    placeholder='Hours'
                    className='form-control'
                    onChange={(e) => setDurationHours(Number(e.target.value))}
                    name='unixStopTime'
                  />
                  <Input
                    type='number'
                    min='0'
                    max='59'
                    placeholder='Minutes'
                    className='form-control'
                    onChange={(e) => setDurationMinutes(Number(e.target.value))}
                    name='unixStopTime'
                  />
                  <Input
                    type='number'
                    min='0'
                    max='59'
                    placeholder='Seconds'
                    className='form-control'
                    onChange={(e) => setDurationSeconds(Number(e.target.value))}
                    name='unixStopTime'
                  />

                  {/* <Select
                    value={SelectedOption}
                    onChange={setDurationYears}
                    options={options_Years}
                    placeholder='Years'
                    defaultValue={0}
                  />
                  <Select
                    value={SelectedOption}
                    onChange={setDurationWeeks}
                    options={options_Weeks}
                    placeholder='Weeks'
                    defaultValue={0}
                  />
                  <Select
                    value={SelectedOption}
                    onChange={setDurationDays}
                    options={options_Days}
                    placeholder='Days'
                    defaultValue={0}
                  />
                  <Select
                    value={SelectedOption}
                    onChange={setDurationHours}
                    options={options_Hours}
                    placeholder='Hours'
                    defaultValue={0}
                  />
                  <Select
                    value={SelectedOption}
                    onChange={setDurationMinutes}
                    options={options_Mins}
                    placeholder='Minutes'
                    defaultValue={0}
                  /> */}
                </div>
                <div className='error'>{stopTimeError}</div>
              </div>
            </div>
            <br />
            <div className='row '>
              <div className=' col-sm'>
                <h6>Amount</h6>
                <input
                  className='form-control'
                  value={deposit}
                  onChange={(e) => {
                    onDepositChange(e.target.value);
                  }}
                  placeholder='Deposit'
                  type='number'
                  name='deposit'
                  required
                />
              </div>
              <div className='form-group col-sm'>
                <h6>Total Tokens</h6>
                <div className='weiVal'>
                  {Web3.utils.fromWei(deposit)}{' '}
                  <span>{TOKENSADDRESSES.get(tokenAddress.toLowerCase())}</span>
                </div>
              </div>
            </div>
            <div className='error'>{depositError}</div>
            <div className='row ' style={{ marginTop: '20px' }}>
              <div className=' col-sm'>
                {recommendations.length ? (
                  <>
                    <p>Some recommended deposits you can try</p>
                    <div>
                      <ul
                        style={{
                          listStyleType: 'none',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          margin: '0',
                          padding: '0',
                        }}>
                        {recommendations.map((e) => (
                          <li
                            key={e}
                            style={{
                              border: '1px solid #00000078',
                              borderRadius: '3px',
                              padding: '2px 5px',
                              cursor: 'pointer',
                              width: 'max-content',
                            }}
                            onClick={() => onDepositChange(e)}>
                            {Web3.utils.fromWei(e)}{' '}
                            <span>
                              {TOKENSADDRESSES.get(tokenAddress.toLowerCase())}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <br />
            {depositError ||
            startTimeError ||
            stopTimeError ||
            !recipient ||
            !tokenAddress ||
            !unixStartTime ||
            !unixStopTime ||
            !deposit ? (
              <button disabled className='button'>
                Create Stream
              </button>
            ) : (
              <button className='button'>Create Stream</button>
            )}
          </form>
        </div>
      </header>
      {processing && (
        <Modal
          centered
          closable={false}
          wrapClassName='create-modal'
          width='20%'
          visible={true}
          footer={null}>
          <h5>{processing}</h5>
          <Spin />
        </Modal>
      )}
    </div>
  );
};

export default Stream;
