import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { message } from 'antd';
import { AccountContext } from './context/AccountContext';
import Navbar from './Components/Navbar/Navbar';
import Dashboard from './Components/Dashboard/Dashboard';
import HomePage from './Components/HomePage/HomePage';
import './App.less';

function App() {
  const [account, setAccount] = useState('');
  const [chainId, setChainId] = useState('');

  useEffect(() => {
    if (window.ethereum) {
      const setChain = async () => {
        const chainID = await window.ethereum.request({
          method: 'eth_chainId',
        });
        if (
          chainID === '0x61' ||
          chainID === '0x38' ||
          chainID === '97' ||
          chainID === '56'
        ) {
          setChainId(chainID);
        }
      };
      setChain();
    } else {
      message.error(
        'Please install Metamask wallet extension to use this app.'
      );
    }
  }, []);

  const changeAccount = (newAccount) => {
    setAccount(newAccount);
  };

  const changeNetwork = (network) => {
    if (network === 'mainnet') {
      window.ethereum
        .request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x38',
              chainName: 'Binance Smart Chain',
              nativeCurrency: {
                name: 'Binance Coin',
                symbol: 'BNB',
                decimals: 18,
              },
              rpcUrls: ['https://bsc-dataseed.binance.org/'],
            },
          ],
        })
        .catch((error) => {
          message.error(error);
        });
    } else if (network === 'testnet') {
      window.ethereum
        .request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x61',
              chainName: 'BSC Testnet',
              nativeCurrency: {
                name: 'Binance Coin',
                symbol: 'BNB',
                decimals: 18,
              },
              rpcUrls: ['https://data-seed-prebsc-1-s2.binance.org:8545/'],
            },
          ],
        })
        .catch((error) => {
          message.error(error);
        });
    }
  };

  const contextValue = {
    account,
    chainId,
    changeNetwork,
    changeAccount,
  };

  if (window.ethereum) {
    window.ethereum.on('accountsChanged', function (accounts) {
      if (
        accounts[0] &&
        accounts[0].toLocaleLowerCase() !== account.toLocaleLowerCase()
      ) {
        changeAccount(accounts[0]);
      } else {
        changeAccount('');
      }
    });

    window.ethereum.on('chainChanged', (chainID) => {
      setChainId(chainID);
    });
  }

  return (
    <AccountContext.Provider value={contextValue}>
      <div className='App-header'>
        <Router>
          <Navbar />
          <Switch>
            <Route path='/dashboard'>
              <Dashboard chainId={chainId} changeChain={changeNetwork} />
            </Route>
            <Route path='/'>
              <HomePage />
            </Route>
          </Switch>
        </Router>
      </div>
    </AccountContext.Provider>
  );
}

export default App;
