import React from 'react';
import { Link } from 'react-router-dom';
import './HomePage.less';
import info from '../../images/info_2.png';
import QuillAuditsBlue from '../../images/QuillAuditsBlueHor.png';

const HomePage = () => {
  return (
    <main className='page-container'>
      <div className='main-section'>
        <div>
          <h2>The real-time finance protocol</h2>
          <Link to='/dashboard'>
            <button type='button' className='dashboard'>
              USE DAPP
            </button>
          </Link>

          <h6>
            <b>Secured By: </b>{' '}
            <img src={QuillAuditsBlue} alt='Quill Audits Logo' />
          </h6>
        </div>
      </div>
      <div className='background-img'>
        <img src={info} alt='' />
        <p></p>
      </div>
    </main>
  );
};

export default HomePage;
