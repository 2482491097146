import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { message } from 'antd';
import { useContext } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AccountContext } from '../../context/AccountContext';
import { connectWallet } from '../../helpers/wallet';
import './Navbar.less';
import wallet from '../../images/wallet.svg';
import logo from '../../images/TokenStreamLogo.png';

const Navbar = ({ location }) => {
  const { account, changeAccount, chainId } = useContext(AccountContext);

  const onConnectWallet = async () => {
    if (window.ethereum) {
      let response = await connectWallet();
      if (response.status) {
        changeAccount(response.account);
        message.success('Successfully connected!');
      } else {
        message.error('Something went wrong!');
      }
    } else {
      message.error(
        'Please install Metamask wallet extension to use this app.'
      );
      setTimeout(() => {
        let popup = window.open('https://metamask.io/', '_blank');
        if (popup) {
          popup.focus();
        } else {
          message.error(
            'You have disabled popups. Please enable the popups or follow this link: https://metamask.io/'
          );
        }
      }, 3000);
    }
  };

  const navlist = (
    <div className='tabs-container'>
      {!account ? (
        location.pathname.includes('/dashboard') ? (
          <button type='button' className='wallet' onClick={onConnectWallet}>
            <span className='desktop'>CONNECT WALLET</span>
            <img className='mobile' src={wallet} alt='wallet icon' />
          </button>
        ) : (
          <Link to='/dashboard'>
            <button type='button' className='wallet'>
              <span className='desktop'>USE DAPP</span>
              <img className='mobile' src={wallet} alt='wallet icon' />
            </button>
          </Link>
        )
      ) : (
        <div className='navigations'>
          {chainId === '0x61' || chainId === '0x38' ? (
            <div className='account' title={account}>
              <CopyToClipboard
                text={account}
                onCopy={() => message.success('Copied your wallet address.')}>
                <span>{`${account.slice(0, 15)}...`}</span>
              </CopyToClipboard>
            </div>
          ) : (
            <div className='wrong-network'>Wrong Network</div>
          )}
        </div>
      )}
    </div>
  );

  return (
    <div>
      <div className='nav-container'>
        <Link to='/'>
          <div className='logo'>
            <img className='image' src={logo} alt='Token stream logo' />
          </div>
        </Link>
        <div>{navlist}</div>
      </div>
    </div>
  );
};

export default withRouter(Navbar);
